import { get } from 'lodash';
import { PaymentGatewayType } from '../../+payment/model';
import { LearningCenterDataQuery } from '../../core/register/model';
import { LearningCenterForm } from '../model';

export const centerEditPutParser = (data: LearningCenterForm, file: string,
    chop: string, base64BusinessFile: any, businessUrl?: string): LearningCenterDataQuery => {
  const {
    email,
    firstName,
    lastName,
    note,
    id,
    contactNumber,
    name,
    address,
    country,
    postalCode,
    taxNumber,
    alternativeName,
    url,
    active,
    countryCode,
    timeZone,
    currencyCode,
    contactEmail,
    paymentGatewayTypeCreditCardSelected,
    isPaymentGatewayTypePayme,
    isPaymentGatewayTypePaymentLater,
    isPaymentGatewayTypePayment2C2PQrAlipay,
    isPaymentGatewayTypePayment2C2PQrFps,
    paymentGatewayTypeSettings,
    // === Credit Card service fee ===
    serviceFee,
    applyServiceFee,
    applyServiceFeeExplainMessage,
    // === PayMe service fee ===
    serviceFeePayme,
    applyServiceFeePayme,
    applyServiceFeeExplainMessagePayme,
    // === Alipay service fee ===
    serviceFeeAlipay,
    applyServiceFeeAlipay,
    applyServiceFeeExplainMessageAlipay,
    // === Alipay service fee ===
    serviceFeeFps,
    applyServiceFeeFps,
    applyServiceFeeExplainMessageFps,
    // ===
    onlinePaymentForAdminGeneratedInvoice,
    learningCenterGroup,
    // === Related Payment 2C2P ===
    licensed,
    licenseNumber,
    payment2c2pMerchantId,
    paymentIsDirect,
    // ===
    redirectingUrl,

    isPaymentGatewayTypePaymentAsia,

    paymentAsiaMerchantId,

    paymentAsiaMerchantToken,

    paymentAsiaMerchantSecret,

    paymentAsiaTerminalId,

    paymentAsiaTerminalApiKey,

    terminalId,

    paymentAsiaId,

    paymentAsiaTerminalName,

    paymentAsiaMerchantName,

    isQrCodePaymentAsia,
    appliedServiceFeePAQRCode,
    serviceFeePaymentAsiaQRCode,
    serviceFeeExplainedMessageAsiaQrCode,

    isCreditCardPaymentAsia,
    appliedServiceFeePACreditCard,
    serviceFeePaymentAsiaCreditCard,
    serviceFeeExplainedMessageAsiaCreditCard
  } = data;

 const paymentGatewayTypeAdyen = paymentGatewayTypeSettings.find(x => x.paymentGatewayType === PaymentGatewayType.ADYEN);
 const paymentGatewayTypePayme = paymentGatewayTypeSettings.find(x => x.paymentGatewayType === PaymentGatewayType.PAYME);
 const paymentGatewayTypePaymentLater = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_LATER);
 const paymentGatewayTypePayment2C2PCreditCard = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD);
 const paymentGatewayTypePayment2C2PQRAlipay = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_QR_ALIPAY);
  const paymentGatewayTypePayment2C2PQRFps = paymentGatewayTypeSettings
    .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_QR_FPS);
  const paymentGatewayTypePaymentAsia = paymentGatewayTypeSettings
    .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_PAYMENT_ASIA);
  const paymentGatewayTypePaymentAsiaQrCode = paymentGatewayTypeSettings
    .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_PAYMENT_ASIA_PAYMENT_QR_CODE);
  const paymentGatewayTypePaymentAsiaCreditCard = paymentGatewayTypeSettings
    .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_PAYMENT_ASIA_PAYMENT_CREDITCARD);

  const requestData = {
    id,
    note,
    alternativeName,
    name: data.centerName,
    uploadedFileBase64: file,
    uploadedChopBase64: chop,
    uploadedBusinessBase64: base64BusinessFile,
    businessUrl: businessUrl,
    invoicesInformation: { name, address, country, postalCode, taxNumber },
    admin: {
      id: data.adminId,
      user: { email },
      profile: { firstName, lastName }
    },
    contactNumber: contactNumber,
    country: countryCode,
    timezoneString: timeZone,
    currency: currencyCode,
    url: url,
    isActive: active,
    contactEmail,
    // === Credit Card service fee ===
    serviceFee: Math.round(serviceFee * 100),
    applyServiceFee,
    applyServiceFeeExplainMessage: applyServiceFeeExplainMessage ? applyServiceFeeExplainMessage.trim() : '',
    // === PayMe service fee ===
    serviceFeePayme: Math.round(serviceFeePayme * 100),
    applyServiceFeePayme,
    applyServiceFeeExplainMessagePayme: applyServiceFeeExplainMessagePayme ? applyServiceFeeExplainMessagePayme.trim() : '',
    // === Alipay service fee ===
    serviceFeeAlipay: Math.round(serviceFeeAlipay * 100),
    applyServiceFeeAlipay,
    applyServiceFeeExplainMessageAlipay: applyServiceFeeExplainMessageAlipay ? applyServiceFeeExplainMessageAlipay.trim() : '',
    // === Fps service fee ===
    serviceFeeFps: Math.round(serviceFeeFps * 100),
    applyServiceFeeFps,
    applyServiceFeeExplainMessageFps: applyServiceFeeExplainMessageFps ? applyServiceFeeExplainMessageFps.trim() : '',
    // ===
    paymentGatewayTypeSettings: [
      {
        id: get(paymentGatewayTypeAdyen, 'id'),
        paymentGatewayType: PaymentGatewayType.ADYEN,
        active: paymentGatewayTypeCreditCardSelected === PaymentGatewayType.ADYEN
      },
      {
        id: get(paymentGatewayTypePayme, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYME,
        active: isPaymentGatewayTypePayme
      },
      {
        id: get(paymentGatewayTypePaymentLater, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_LATER,
        active: isPaymentGatewayTypePaymentLater
      },
      {
        id: get(paymentGatewayTypePayment2C2PCreditCard, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD,
        active: paymentGatewayTypeCreditCardSelected === PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD
      },
      {
        id: get(paymentGatewayTypePayment2C2PQRAlipay, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_QR_ALIPAY,
        active: isPaymentGatewayTypePayment2C2PQrAlipay
      },
      {
        id: get(paymentGatewayTypePayment2C2PQRFps, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_QR_FPS,
        active: isPaymentGatewayTypePayment2C2PQrFps
      },
      {
        paymentGatewayType: PaymentGatewayType.PAYMENT_PAYMENT_ASIA,
        active: isPaymentGatewayTypePaymentAsia,
        id: get(paymentGatewayTypePaymentAsia, 'id'),
      },
      {
        paymentGatewayType: PaymentGatewayType.PAYMENT_PAYMENT_ASIA_PAYMENT_QR_CODE,
        active: isQrCodePaymentAsia,
        id: get(paymentGatewayTypePaymentAsiaQrCode, 'id'),
        serviceFee: Math.round(serviceFeePaymentAsiaQRCode * 100),
        serviceFeeApplied: appliedServiceFeePAQRCode,
        serviceFeeExplainedMessage: serviceFeeExplainedMessageAsiaQrCode ? serviceFeeExplainedMessageAsiaQrCode.trim() : '',
      },
      {
        paymentGatewayType: PaymentGatewayType.PAYMENT_PAYMENT_ASIA_PAYMENT_CREDITCARD,
        active: isCreditCardPaymentAsia,
        id: get(paymentGatewayTypePaymentAsiaCreditCard, 'id'),
        serviceFee: Math.round(serviceFeePaymentAsiaCreditCard * 100),
        serviceFeeApplied: appliedServiceFeePACreditCard,
        serviceFeeExplainedMessage: serviceFeeExplainedMessageAsiaCreditCard ?
          serviceFeeExplainedMessageAsiaCreditCard.trim() :
          '',
      }
    ],
    allowInvofflinePayonline: onlinePaymentForAdminGeneratedInvoice,
    learningCenterGroup: learningCenterGroup ? { id: learningCenterGroup.id } : null,
    licensed: licensed,
    licenseNumber: licenseNumber,
    payment2c2pMerchantId: payment2c2pMerchantId,
    paymentIsDirect: paymentIsDirect,
    redirectingUrl: redirectingUrl,

    asiaPaymentMerchantAccount: isPaymentGatewayTypePaymentAsia ? {
      merchantId: paymentAsiaMerchantId,
      merchantToken: paymentAsiaMerchantToken,
      merchantSecretCode: paymentAsiaMerchantSecret,
      merchantName: paymentAsiaMerchantName,

      id: paymentAsiaId ? paymentAsiaId : undefined,
      posTerminal: {
        terminalId: paymentAsiaTerminalId,
        terminalName: paymentAsiaTerminalName,
        apiKey: paymentAsiaTerminalApiKey,
        id: terminalId ? terminalId : undefined,
      }
    } : null,
  };

  return requestData;
};

export const centerProfileEditPutparse = (requestData: any, formData: any, file: string, chop: string): any => {
  const {admin, id} = requestData;
  const {centerName, alternativeName, name, address, country, postalCode, taxNumber, contactEmail} = formData;
  const returnData = {
    name: centerName,
    admin: admin,
    invoicesInformation: {
      name,
      address,
      country,
      postalCode,
      taxNumber,
    },
    alternativeName,
    uploadedFileBase64: file,
    uploadedChopBase64: chop,
    id,
    contactEmail
  };
  return returnData;
};
