import {QueryList} from '../../store/query';
import {Student} from '../../+student/model';
import {Lesson} from '../../+lesson/model';
import {Parent} from '../../+parent/model';
import {Location} from '../../+settings/model/settings.model';
import {StaffMember} from '../../shared/model/staff-member.model';
import {Payment} from './payment.model';

export enum PaymentGatewayType {
  ADYEN = 'ADYEN',
  PAYME = 'PAYMEHSBC',
  PAYMENT_LATER = 'PAYMENT_LATER',
  PAYMENT_2C2P_CREDIT_CARD = 'PAYMENT_2C2P_CREDIT_CARD',
  PAYMENT_2C2P_QR_ALIPAY = 'PAYMENT_2C2P_QR_ALIPAY', // Digital Payment (DPAY)
  PAYMENT_2C2P_QR_FPS = 'PAYMENT_2C2P_QR_FPS', // QR Payment (FPS QR HK)
  PAYMENT_PAYMENT_ASIA = 'PAYMENT_ASIA_PAYMENT',
  PAYMENT_PAYMENT_ASIA_PAYMENT_QR_CODE = 'PAYMENT_ASIA_PAYMENT_QR_CODE',
  PAYMENT_PAYMENT_ASIA_PAYMENT_CREDITCARD = 'PAYMENT_ASIA_PAYMENT_CREDITCARD',

  //
  CREDIT_CARD_OFF = 'CREDIT_CARD_OFF',
}


export enum PaymentMethod {
  None = 'none',
  Various = 'various',
  Cash = 'cash',
  Card = 'card',
  Transfer = 'transfer',
  Cheque = 'cheque',
  Card_Online = 'card_online', // Adyen credit card, 2C2P credit card
  Card_Online_Payme = 'card_online_payme', // EngagePlus's PayMe with QR Code
  PAYMENT_2C2P_QR_ALIPAY = 'payment_2c2p_qr_alipay',  // Alipay with QR Code method
  PAYMENT_2C2P_DPAY_PAYME = 'payment_2c2p_dpay_payme', // 2C2P's PayMe with QR Code
  PAYMENT_2C2P_QR_FPS = 'payment_2c2p_qr_fps', // 2C2P's FPS with QR Code
  PAYMENT_ASIA_CREDIT_CARD = 'payment_asia_creditcard',
  PAYMENT_ASIA_QR_CODE = 'payment_asia_qr_code',
}

export const PaymentMethodOnline = [
  PaymentMethod.Card_Online,
  PaymentMethod.Card_Online_Payme,
  PaymentMethod.PAYMENT_2C2P_QR_ALIPAY,
  PaymentMethod.PAYMENT_2C2P_DPAY_PAYME,
  PaymentMethod.PAYMENT_2C2P_QR_FPS,
];

export const PaymentMethodCardTerminal = [
  PaymentMethod.PAYMENT_ASIA_CREDIT_CARD,
  PaymentMethod.PAYMENT_ASIA_QR_CODE,
];

export enum PaymentDiscount {
  Percentage = 'percentage',
  Amount= 'amount',
}

export enum PaymentStatus {
  Paid = 'paid',
  NotPaid = 'not_paid',
  PartiallyPaid = 'partially_paid',
  PaidOnline = 'paid_online'
}

export enum HistoryEvent {
  Paid = 'paid',
  PdfDownloaded = 'pdf_downloaded',
  DueDatePassed = 'due_date_passed',
  Corrected = 'corrected',
  SentToClient = 'sent_to_client',
  Created = 'created',
  Unpaid = 'unpaid',
}

export interface HistoryMetadata {
  method?: string;
  amount?: number;
  currency?: string;
  invoiceId?: string;
  invoicePrefix?: string;
  invoiceNumber?: number;
}

export interface InvoicePerson {
  address: string;
  city: string;
  country: string;
  name: string;
  postalCode: number;
  taxNumber: string;
}

export interface InvoiceItem {
  name: string;
  quantity: number;
  unitPrice: number;
  student: Student;
  discount?: number;
  discountType?: string;
  teacherName?: string;
  teacherArchived?: boolean;
  courseCode?: string;
  attachedStudent?: any;
  lessonSnapshot?: any;
  pickMonth?: any;
  numberOfLesson?: number;
  pickLesson?: any;
  groupId?: string;
  isMainItem?: boolean;
  lessonPriceType?: 'per_lesson' | 'monthly';
}

export interface InvoiceMiscellaneousItem {
  name: string;
  quantity: number;
  unitPrice: number;
  student?: string;
  discount?: number;
  discountType?: string;
  coursePackageType?: string;
  coursePackage?: string;
  coursePackageDetails?: string;
  monthlyInvoice?: string;
}

export interface InvoiceMonthlyItem {
  name: string;
  quantity: number;
  unitPrice: number;
  student: Student;
  discount?: number;
  discountType?: string;
  teacherName?: string;
  attachedStudent?: any;
  lessonSnapshot?: any;
}

export interface Invoice {
  id?: string;
  parent?: Parent;
  correct?: Invoice; // corrective
  correction?: Invoice; // voided
  recipient?: InvoicePerson;
  issuer?: InvoicePerson;
  issueDate?: string;
  dueDate?: string;
  createdAt?: string;
  number?: number;
  paid?: {
    done: boolean;
    additionalInformation: string;
    method: string;
  };
  paymentGatewayType?: PaymentGatewayType;
  paymentMethod?: PaymentMethod;
  paymentStatus?: string;
  partialPayment?: boolean;
  paidAmount?: number;
  fullyPaid?: boolean;
  item?: InvoiceItem[];
  items?: any;
  miscellaneousItem?: InvoiceMiscellaneousItem[];
  prefix?: string;
  sent?: string;
  title?: string;
  totalGross?: number;
  totalNet?: number;
  totalTax?: number;
  updatedAt?: string;
  students?: InvoiceStudent[];
  invoiceKindOf?: string;
  coursePackageType?: string;
  lessonChange?: boolean;
  payslipNotEmpty?: boolean;
  payslipRenewed?: boolean;
  payments?: Payment[];
  location?: Location;
}

export interface InvoiceHistory {
  id?: string;
  invoice?: string;
  author?: string;
  metadata?: HistoryMetadata;
  event?: string;
  date?: string;
}

export interface InvoiceQuery {
  dueDate: string;
  parent: string;
  items: InvoiceItem[];
  miscellaneousItems?: InvoiceMiscellaneousItem[];
  tax: number;
  discount: number;
  discountType: string;
  order: number;
  parentInvoiceId?: string;
  invoiceKindOf?: string;
  correct?: any;
}

export interface InvoiceMail {
  ids: string[];
}

export interface InvoiceProfile {
  localName: string;
}

export interface InvoiceParent {
  id: string;
  lastInvoice: string;
  profile: InvoiceProfile;
}

export interface InvoiceStudent {
  id: string;
  profile: InvoiceProfile;
}

export interface PartialPayment {
  id?: string;
  method?: string;
  amount?: number;
  invoice?: string;
  number?: string;
  paymentDate?: string;
}

export interface InvoicesPaymentStatusesInformations {
  invoicesPaymentStatusesInformation?: InvoicesPaymentStatusesInformation[];
}

export interface InvoicesPaymentReference {
  id: string;
  referenceNumber: string;
}

export interface InvoicesPaymentReferences {
  invoicesReferences: InvoicesPaymentReference[];
}

export interface InvoicesPaymentStatusesInformation {
  invoiceId: string;
  paymentInformation: {
    done: boolean;
    method: string;
    number: string;
  };
  uploadedFileInformationList?: File[];
}

export interface PendingInvoiceForList {
  invoice: Invoice;
  client: InvoiceParent;
}

export interface PendingInvoice {
  id?: string;
  student?: Student;
  students?: any[];
  parent?: Parent;
  dueDate?: string;
  orderNumber?: number;
  internalNote?: string;
  externalNote?: string;
  tax?: number;
  discount?: number;
  discountType?: string;
  generatedFailed?: boolean;
  attachedStudentInvoicePendingItems?: AttachedStudentInvoicePendingItem[];
  miscellaneousInvoicePendingItems?: MiscellaneousInvoicePendingItem[];
  removedInvoicePendingItemIds?: string[];
  attachedStudentInvoicePendingItemsCount?: number;
  miscellaneousInvoicePendingItemsCount?: number;
  totalInvoicePendingItemsCount?: number;
  hasLessonDifferentPrice?: boolean;
  profile?: string;
  lastInvoice?: string;
  totalGross?: number;
  monthlyAttachedStudentInvoicePendingItems?: any[];
  perLessonAttachedStudentInvoicePendingItems?: any[];
}

export interface PendingInvoiceItem {
  HasInvoice?: boolean;
  absenceAction: string;
  id?: string;
  lesson: Lesson;
  onlineRegister: boolean;
  recurring: boolean;
}

export interface AttachedStudentInvoicePendingItem {
  lessonPriceType: 'monthly' | 'per_lesson';
  id?: string;
  name: string;
  quantity: number;
  unitPrice: number;
  student: Student;
  discount?: number;
  discountType?: string;
  teacherName?: string;
  teacherArchived?: boolean;
  courseCode?: string;
  attachedStudent?: any;
  lessonSnapshot?: any;
  numberOfLesson?: number;
  pickLesson?: any;
  lesson?: any;
  isMainItem?: boolean;
  groupId?: string;
}

export interface MiscellaneousInvoicePendingItem {
  id?: string;
  name: string;
  quantity: number;
  unitPrice: number;
  student?: string;
  discount?: number;
  discountType?: string;
}

export interface QueryPutPendingInvoice {
  id?: string;
  dueDate?: string;
  orderNumber?: number;
  internalNote?: string;
  externalNote?: string;
  tax?: number;
  discount?: number;
  discountType?: string;
  attachedStudentInvoicePendingItems?: AttachedStudentInvoicePendingItem[];
  miscellaneousInvoicePendingItems?: MiscellaneousInvoicePendingItem[];
  removedInvoicePendingItemIds?: string[];
}

export interface QueryPostPendingInvoice {
  from: string;
  to: string;
  students: string[];
}

export interface QueryPostPendingGenerateInvoice {
  invoicePendingIds: string[];
}

export interface QueryOutputInvoice {
  ids?: string[];
  fromDate?: string;
  toDate?: string;
}

export interface InsertProcessingInvoice {
  invoice_list: string[];
}

export interface DeleteProcessingInvoice {
  invoice_list: string[];
}

export interface InvoicePayslip {
  id?: string;
  name?: string;
  uploadType?: InvoicePayslipUploadTypeEnum;
  staffMember?: StaffMember;
  userBooking?: any;
  url?: string;
  link?: string;
  file?: File;
}

export interface File {
  fileName?: string;
  uploadedFileBase64?: string;
}

export enum InvoicePayslipUploadTypeEnum {
  STAFF_MEMBER = 'staff_member',
  USER = 'user',
  ANONYMOUS = 'anonymous'
}

export interface InvoicePayslipConfirmedInformation {
  invoiceId: string;
  paymentInformation: {
    paymentDate: string,
    number: string;
  };
}

export type InvoiceHistories = QueryList<InvoiceHistory[]>;
export type Invoices = QueryList<Invoice[]>;
export type PendingInvoices = QueryList<PendingInvoice[]>;
export type InvoiceMiscellaneousItems = QueryList<InvoiceMiscellaneousItem[]>;
